import { ReservationFilterType } from '../../../@types';

const defaultValues: ReservationFilterType = {
    startAt: new Date(),
    endAt: new Date(),
    organizationalUnits: [],
    license: null,
    professional: null,
    room: null,
    personName: '',
    personBirthDate: null,
    personDocument: '',
    statuses: [],
    procedureName: '',
    user: null,
    tags: []
};

export default defaultValues;
