import { useEffect, useState } from "react";
import { MenuItem, Stack } from "@mui/material";
import { useI18n } from "@wi-core/ui";
import { WiLocales } from "@wi-core/ui/src/resources/wi-locales/WiLocales";
import WiLocalesType from "@wi-core/ui/src/resources/wi-locales/WiLocalesType";
import IconButtonAnimate from '@wi-themes/minimal/src/components/animate/IconButtonAnimate';
import { LabelType } from "./../wi-country-flags/types/WiCountryFlagsProps";
import WiCountryFlags from "./../wi-country-flags/WiCountryFlags";
import WiMenuPopover from "../wi-menu-popover/WiMenuPopover";

import WiSelectLanguageProps from "./types/WiSelectLanguageProps";



const WiSelectLanguage: React.FC<WiSelectLanguageProps> = () => {

    const { changeLanguage, lang } = useI18n();

    const [open, setOpen] = useState<HTMLElement | null>(null);
    const [currentValue, setCurrentValue] = useState<WiLocalesType>(WiLocales[0]);

    useEffect(() => {
        const config = WiLocales.find(e => e.language === lang.language);

        if (config != null) {
            setCurrentValue(config);
        }
    }, [])

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const onSelectLanguage = (config: WiLocalesType) => {

        setCurrentValue(config);
        changeLanguage(config.language, config.themeLanguage);

        handleClose();
    }

    const renderMenuItems = () => {
        return WiLocales.map(e => {
            return (
                <MenuItem 
                    key={e.key} 
                    selected={e.key === currentValue.key} 
                    onClick={() => onSelectLanguage(e)}>

                    <WiCountryFlags 
                        config={e} 
                        labelType={LabelType.language} 
                        lang={lang.language} 
                        flagSize={{width: 28}}/>

                </MenuItem>
            );
        });
    }

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    ...(open && { bgcolor: 'action.selected' }),
                }}
            >
                <WiCountryFlags config={currentValue} labelType={LabelType.none} lang={lang.language} />
            </IconButtonAnimate>

            <WiMenuPopover
                anchorEl={open}
                onClose={handleClose}
                arrowPosition="top-right"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                sx={{
                    '.MuiPaper-root': {
                        minWidth: 180,
                        '& .MuiMenuItem-root': { 
                            px: 1, 
                            typography: 'body2', 
                            borderRadius: 0.75 
                        }
                    }
                }}
            >
                <Stack spacing={0.75}>
                    {renderMenuItems()}
                </Stack>
            </WiMenuPopover>
        </>
    );
}

export default WiSelectLanguage;