import { Localization } from "@mui/material/locale";
import { Resource } from "i18next";

export enum I18nLanguages {
    En = 'en',
    PtBr = 'pt_br'
}

export type I18nCurrentLanguage = {
    language: I18nLanguages;
    themeLanguage: Localization;
}

export type I18nType = {
    translate: (text: string, ns?: string) => string;
    translateWithParameters: (text: string, properties: any) => string,
    changeLanguage: (language: I18nLanguages, themeLanguage: Localization) => void;
    lang: I18nCurrentLanguage
};

export type I18nProviderType = { 
    resources: Resource;
    defaultNS?: string;
    children: JSX.Element | JSX.Element[] | string | string[];
}