import { ProcedureType } from "../../procedure";
import AppointmentDisponibilityType from "../@types/appointment-disponibility-type/AppointmentDisponibilityType";
import AppointmentStatus from "../@types/appointment-status/AppointmentStatus";
import AppointmentType from "../@types/appointment-type/AppointmentType";
import SearchAppointmentResponse from "../@types/search-appointment/SearchAppointmentResponse";


const DefaultValuesSearchAppointmentResponse: SearchAppointmentResponse = {
    id: '',
    licenseKey: '',
    licenseName: '',
    organizationalUnitKey: '',
    organizationalUnitName: '',
    organizationalUnitOffSet: '',
    personId: '',
    personName: '',
    personDocument: '',
    personContact: '',
    professionalId: '',
    professionalName: '',
    professionalRegionalCouncilNumber: '',
    roomId: '',
    roomName: '',
    startAt: '',
    endAt: '',
    type: AppointmentType.Normal,
    disponibilityType: AppointmentDisponibilityType.Normal,
    healthPlans: [],
    healthCardId: '',
    healthCardCode: '',
    healthCardValidity: '',
    status: AppointmentStatus.Scheduling,
    additionalAppointment: false,
    hasPendency: false,
    pendingAuthorization: false,
    pendingEligibility: false,
    pendingPersonIntegration: false,
    pendingPayment: false,
    waitingInTheQueue: false,
    procedureType: ProcedureType.Exam,
    procedures: [],
    observation: '',
    allowedToPrint: false,
    allowedToMarkAccomplished: false,
    allowedToMarkAwaitingAttendance: false,
    allowedToMarkCanceled: false,
    allowedToMarkConfirmed: false,
    allowedToMarkNoShow: false,
    allowedToIntegrateAppointment: false,
    allowedToIntegrateAttendance: false,
    allowedToChangePerson: false,
    allowedToChangeProcedure: false,
    allowedToChangeHealthPlan: false,
    allowedToChangeDisponibility: false,
    tags: []
}

export default DefaultValuesSearchAppointmentResponse;