import AppointmentPermissions from "../@types/appointment-permissions/AppointmentPermissions";

import AppointmentStatus from "../@types/appointment-status/AppointmentStatus";

const AppointmentPermissionByStatus: { [key: string]: AppointmentPermissions } = {
    [AppointmentStatus.Confirmed]: {
        allowedToMarkCanceled: true,
        allowedToMarkConfirmed: false,
        allowedToChangeDisponibility: true,
        allowedToChangeProcedure: true,
    },
    [AppointmentStatus.AwaitingAttendance]: {
        allowedToMarkCanceled: true,
        allowedToMarkConfirmed: false,
        allowedToChangeDisponibility: true,
        allowedToChangeProcedure: true,
    },
    [AppointmentStatus.Canceled]: {
        allowedToMarkCanceled: false,
        allowedToMarkConfirmed: false,
        allowedToChangeDisponibility: false,
        allowedToChangeProcedure: false,
    },
    [AppointmentStatus.NoShow]: {
        allowedToMarkCanceled: true,
        allowedToMarkConfirmed: true,
        allowedToChangeDisponibility: true,
        allowedToChangeProcedure: true,
    }
}

export default AppointmentPermissionByStatus;