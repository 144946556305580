import WiPrintProfileContextType from "../@types/WiPrintProfileContextType";
import WiPrintProfileType from "../@types/WiPrintProfileType";


const InitialValues: WiPrintProfileContextType = {
    loading: false,
    data: {
        deviceId: '',
        printProfile: null
    },
    handleChangeProfile: async (profile: WiPrintProfileType | null) => false
};

export default InitialValues;
