import WiCountryFlagsProps, { LabelType } from './types/WiCountryFlagsProps';
import { Box } from '@mui/material';
import i18n from './i18n';

// components
import Image from '@wi-themes/minimal/src/components/Image';

import './WiCountryFlags.less';


const WiCountryFlags: React.FC<WiCountryFlagsProps> = (props: WiCountryFlagsProps) => {

    const {
        config,
        labelPosition,
        labelType,
        lang,
        flagSize
    } = props;

    const renderLabel = () => {
        if (labelType === LabelType.none) return;

        let result = i18n[lang][config.key].name;

        if (labelType === LabelType.language) {
            result = i18n[lang][config.key].languageLabel;
        }

        return <div className="label">{result}</div>;
    }

    return (
        <div className="country-flags">
            <Box className={`content ${labelPosition || 'right'}`}>
                <Image disabledEffect src={config.icon} alt={config.name} sx={{...flagSize}}/>
                {renderLabel()}
            </Box>
        </div>
    )
}

export default WiCountryFlags;