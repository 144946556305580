import { WiLabelColor } from "@wi-themes/minimal-override";

import AppointmentStatus from "../@types/appointment-status/AppointmentStatus";


const AppointmentStatusColor: { [key: string]: WiLabelColor } = {
    [AppointmentStatus.Scheduling]: 'success',
    [AppointmentStatus.Scheduled]: 'success',
    [AppointmentStatus.Confirmed]: 'success',
    [AppointmentStatus.Canceled]: 'error',
    [AppointmentStatus.AwaitingAttendance]: 'warning',
    [AppointmentStatus.Accomplished]: 'success',
    [AppointmentStatus.NoShow]: 'default',
    [AppointmentStatus.Transfer]: 'warning'
};

export default AppointmentStatusColor;
