import ExternalUserStatus from "../@types/external-user-status/ExternalUserStatus";
import SearchExternalUserResponse from "../@types/search-external-user/SearchExternalUserResponse";


const DefaultValuesSearchExternalUserResponse: SearchExternalUserResponse = {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    name: '',
    emailVerified: false,
    phoneVerified: false,
    persons: [],
    status: ExternalUserStatus.Ok,
    enabled: false,
    createdTimestamp: 0
}

export default DefaultValuesSearchExternalUserResponse;