import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
    disabledLink?: boolean;
}

export default function WiLogo({ disabledLink = false, sx }: Props) {
    const theme = useTheme();
    const PRIMARY_LIGHT = theme.palette.primary.light;
    const PRIMARY_MAIN = theme.palette.primary.main;
    const PRIMARY_DARK = theme.palette.primary.dark;

    const logo = (
        <Box sx={{ width: 52, height: 52, ...sx }}>
            <svg
                viewBox="0 0 500 220.067"
                width="100%"
                height="100%"
                shapeRendering="geometricprecision"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    <linearGradient
                        id="BG1"
                        x1="100%"
                        x2="50%"
                        y1="9.946%"
                        y2="50%"
                    >
                        <stop offset="0%" stopColor={PRIMARY_DARK} />
                        <stop offset="100%" stopColor={PRIMARY_MAIN} />
                    </linearGradient>
                    <linearGradient
                        id="BG2"
                        x1="50%"
                        x2="50%"
                        y1="0%"
                        y2="100%"
                    >
                        <stop offset="0%" stopColor={PRIMARY_LIGHT} />
                        <stop offset="100%" stopColor={PRIMARY_MAIN} />
                    </linearGradient>
                    <linearGradient
                        id="BG3"
                        x1="50%"
                        x2="50%"
                        y1="0%"
                        y2="100%"
                    >
                        <stop offset="0%" stopColor={PRIMARY_LIGHT} />
                        <stop offset="100%" stopColor={PRIMARY_MAIN} />
                    </linearGradient>
                </defs>
                <g
                    id="Camada_x0020_1"
                    transform="matrix(0.311133, 0, 0, 0.311133, -6, 0)"
                >
                    <g id="_3017105475216">
                        <path                            
                            d="M 823.25 517.386 C 733.39 517.386 660.55 444.836 660.55 355.666 L 660.55 193.226 C 660.55 111.536 594.33 45.316 512.64 45.316 L 512.64 355.666 C 512.64 525.626 651.98 664.156 823.25 664.156 C 917.5 664.156 1002.07 622.116 1059.08 555.996 C 1027.67 519.526 1004.56 475.706 992.74 427.496 C 988.58 410.516 985.8 393.966 984.56 376.586 C 974.11 455.816 905.88 517.376 823.25 517.376 L 823.25 517.386 Z"                            
                            fill="url(#BG1)"
                            fillRule="nonzero"      
                        />
                        <path                            
                            d="M 1003.63 355.416 C 1003.63 380.896 1006.74 403.796 1012.55 427.506 C 1045.82 563.186 1168.45 664.166 1314.24 664.166 C 1399.88 664.166 1477.53 629.326 1533.77 573.086 L 1488.35 527.666 C 1458.58 497.896 1413.94 488.886 1374.89 504.586 C 1356.14 512.116 1335.68 516.266 1314.25 516.266 C 1251.03 516.266 1196.28 480.186 1169.34 427.516 C 1151.52 392.266 1151.54 355.426 1151.54 355.426 C 1151.54 328.786 1157.99 301.806 1169.34 279.606 C 1196.27 226.946 1251.03 190.866 1314.25 190.866 C 1359.18 190.866 1399.86 209.076 1429.3 238.516 C 1441.28 250.496 1451.37 264.376 1459.16 279.606 L 1343.94 279.606 C 1366.97 297.166 1381.84 326.316 1381.84 356.086 C 1381.84 385.866 1369.56 409.926 1350.03 427.516 L 1615.94 427.516 C 1621.76 403.806 1624.86 380.896 1624.86 355.426 C 1624.86 329.946 1621.75 303.316 1615.94 279.606 C 1602.22 223.626 1573.29 173.536 1533.78 134.026 C 1477.54 77.786 1399.89 42.946 1314.25 42.946 C 1168.46 42.946 1045.82 143.926 1012.56 279.606 C 1006.75 303.316 1003.64 329.946 1003.64 355.426 L 1003.63 355.416 Z"
                            fill="url(#BG2)"
                            fillRule="nonzero"      
                        />
                        <path                            
                            d="M 493.26 372.086 C 485.11 453.686 415.74 517.386 331.36 517.386 C 241.5 517.386 168.66 445.136 168.66 355.966 L 168.66 193.226 C 168.66 111.536 102.44 45.316 20.75 45.316 L 20.75 355.966 C 20.75 525.926 160.09 664.156 331.36 664.156 C 425.76 664.156 510.45 622.146 567.46 555.986 C 524.31 505.916 496.97 442.036 493.25 372.076 L 493.26 372.086 Z"
                            fill="url(#BG3)"
                            fillRule="nonzero"      
                        />
                        <path
                            d="M 1279.5 279.976 C 1320.92 279.976 1354.51 313.556 1354.51 354.976 C 1354.51 396.406 1320.93 429.986 1279.5 429.986 C 1238.07 429.986 1204.49 396.406 1204.49 354.976 C 1204.49 313.556 1238.07 279.976 1279.5 279.976 Z"
                            fill="url(#BG3)"
                            fillRule="nonzero"      
                        />
                    </g>
                </g>
            </svg>
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
