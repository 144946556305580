import { InconsistenciesStatus } from "@wi-core/shared-blocks";
import WiDialogAppointmentCheckInDataType from "../@types/WiDialogAppointmentCheckInDataType";

const DefaultValues: WiDialogAppointmentCheckInDataType = {
    fullScreen: false,
    loading: false,
    inconsistencies: [],
    status: InconsistenciesStatus.Ok,
    justification: '',
    justificationError: '',
    attendanceId: null
}

export default DefaultValues;