import axios from 'axios';
import Env from '@wi-config/env';
import { HttpStatus } from '@wi-core/shared-blocks';
import loadUser from './user-loader';

const api = axios.create({
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    },
    maxRedirects: 0,
    baseURL: Env.endpoint
});

api.interceptors.request.use(
    config => {

        const user = loadUser();        

        if (user?.access_token && config.headers) {
            config.headers.Authorization = 'Bearer ' + user?.access_token;
        }
        return config;
    },
    () => Promise.reject(Error('Error to request server'))
);

api.interceptors.response.use(response => {
    if(response.data.error && response.data.error.statusCode !== HttpStatus.MultiStatus) {
        return Promise.reject(response.data.error);
    }

    return response;
}, error => {
    if(error.response.data.error) {
        return Promise.reject(error.response.data.error);
    }

    if (error.response.data) {
        return Promise.reject(error.response.data);
    }

    return Promise.reject(error);
});

export default api;

