enum ReservationStatus {
    Reservation = 'Reservation',
    Cancelled = 'Cancelled',
    Transfer = 'Transfer',
    Scheduling = 'Scheduling',
    Scheduled = 'Scheduled',
    Expired = 'Expired'
}

export default ReservationStatus;
