import Env from '@wi-config/env';
import { User } from "oidc-client-ts"

const loadUser = () => {
    const oidcStorage = localStorage
        .getItem(`oidc.user:${Env.oidcConfig.authority}:${Env.oidcConfig.client_id}`);

    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}

export default loadUser;