import { alpha, styled } from '@mui/material/styles';
import { Box, Typography, BoxProps } from '@mui/material';
import WiColorPreviewProps from './@types/WiColorPreviewProps';
import { Theme } from '@fullcalendar/core/internal';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
}));

const IconStyle = styled('div')(({ theme }) => ({
    marginLeft: -4,
    borderRadius: '50%',
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    border: `solid 2px ${theme.palette.background.paper}`,
    boxShadow: `inset -1px 1px 2px ${alpha(theme.palette.common.black, 0.24)}`
}));

// ----------------------------------------------------------------------

const WiColorPreview = (props: WiColorPreviewProps) => {

    const {
        colors,
        width,
        height,
        limit,
        sx
    } = props;

    const currentLimit = limit || 0;
    const showColor = currentLimit > 0 ? colors.slice(0, limit) : colors;
    const moreColor = colors.length - currentLimit;

    return (
        <RootStyle component="span" sx={sx}>
            {showColor.map((color, index) => (
                <IconStyle 
                    key={color + index} 
                    sx={{ 
                        bgcolor: color,
                        width: (theme) => width || theme.spacing(1.5),
                        height: (theme) => height || theme.spacing(1.5),
                    }} 
                />
            ))}

            {moreColor !== colors.length && <Typography variant="subtitle2">{`+${moreColor}`}</Typography>}
        </RootStyle>
    )
}

export default WiColorPreview;