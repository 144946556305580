import { I18nLanguages } from "@wi-core/ui/src/context/i18n/I18nType";
import WiLocalesType from "@wi-core/ui/src/resources/wi-locales/WiLocalesType";

export enum LabelType {
    none = 'none',
    language = 'language',
    country = 'country'
}

type WiCountryFlagsProps = {
    config: WiLocalesType;
    labelType: LabelType;
    labelPosition?: 'top' | 'right' | 'bottom' | 'left';
    lang: I18nLanguages;
    flagSize?: {
        width?: number;
        height?: number;
    }
}


export default WiCountryFlagsProps;