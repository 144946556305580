import { AppointmentDisponibilityType } from "@wi-core/shared-blocks";
import DisponibilityListFilterForm from "./../@types/DisponibilityListFilterForm";

const DefaultValuesFilter: DisponibilityListFilterForm = {
    professional: null,
    organizationalUnit: null,
    professionalError: '',
    intervalBetweenDisponibilities: '',
    intervalBetweenDisponibilitiesError: '',
    appointmentDisponibilityType: AppointmentDisponibilityType.Normal
}

export default DefaultValuesFilter;