import Env from '@wi-config/env';
import OidcConfig from '@wi-config/env/src/types/OidcConfig';

const oidcConfig: OidcConfig = {
    authority: Env.oidcConfig.authority,
    client_id: Env.oidcConfig.client_id,
    redirect_uri: Env.oidcConfig.redirect_uri,
    post_logout_redirect_uri: Env.oidcConfig.post_logout_redirect_uri,
    userStore: Env.oidcConfig.userStore,
    onSigninCallback: Env.oidcConfig.onSigninCallback
};

export default oidcConfig;
