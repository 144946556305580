import React from "react";
import { alpha, Box, IconButton, styled } from "@mui/material";
import Iconify from "@wi-themes/minimal/src/components/Iconify";
import { DownloadButtonProps } from "./@types/DownloadButtonProps";

// ----------------------------------------------------------------------
const RootStyle = styled(Box)(({ theme }) => ({
    zIndex: 9,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.grey[900], 0.48),
}));

const IconStyle = styled(IconButton)(({ theme }) => ({
    padding: 6,
    opacity: 0.48,
    color: theme.palette.common.white,
    '&:hover': { opacity: 1 },
}));

// ----------------------------------------------------------------------

const DownloadButton = (props: DownloadButtonProps) => {

    const {
        href
    } = props;

    return (
        <RootStyle>
            <a href={href} download>
                <IconStyle size="small">
                    <Iconify
                        icon={'eva:download-fill'}
                        sx={{
                            width: 20,
                            height: 20,
                            transform: ' scaleX(-1)'
                        }}
                    />
                </IconStyle>
            </a>
        </RootStyle>
    );
}

export default DownloadButton;