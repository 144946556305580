import React from "react";
import { Box, Typography } from "@mui/material";
import Env from '@wi-config/env';

import useI18n from "../../hooks/useI18n";
import WiLabelVersionProps from "./@types/WiLabelVersionProps";


const WiLabelVersion = (props: WiLabelVersionProps) => {

    const {
        sx
    } = props;

    const { translate } = useI18n();

    return (
        <Box
            sx={{...sx}}
        >
            <Typography variant="caption" noWrap>
                {`${translate('common.version')} ${Env.version}`}
            </Typography>
        </Box>
    )
}

export default WiLabelVersion;