import { alpha, Box, IconButton, styled, Typography, useTheme } from "@mui/material";
import Iconify from "@wi-themes/minimal/src/components/Iconify";
import CarouselArrowIndexProps from "./@types/CarouselArrowIndexProps";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
    zIndex: 9,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.grey[900], 0.48),
}));

const ArrowStyle = styled(IconButton)(({ theme }) => ({
    padding: 6,
    opacity: 0.48,
    color: theme.palette.common.white,
    '&:hover': { opacity: 1 },
}));

// ----------------------------------------------------------------------

const CarouselArrowIndex = (props: CarouselArrowIndexProps) => {

    const {
        index,
        total,
        customIcon,
        onNext,
        onPrevious,
        ...other
    } = props;

    return (
        <RootStyle {...other}>
            <ArrowStyle size="small" onClick={onPrevious}>
                <Iconify
                    icon={customIcon ? customIcon : 'eva:arrow-right-fill'}
                    sx={{
                        width: 20,
                        height: 20,
                        transform: ' scaleX(-1)'
                    }}
                />
            </ArrowStyle>

            <Typography variant="subtitle2">
                {index + 1}/{total}
            </Typography>

            <ArrowStyle size="small" onClick={onNext}>
                <Iconify
                    icon={customIcon ? customIcon : 'eva:arrow-right-fill'}
                    sx={{
                        width: 20,
                        height: 20
                    }}
                />
            </ArrowStyle>
        </RootStyle>
    );
}

export default CarouselArrowIndex;