import { Localization } from '@mui/material/locale';
import i18n, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from 'react';
import { createContext } from 'react';
import { initReactI18next } from 'react-i18next';
import { WiLocales } from '../../resources/wi-locales/WiLocales';
import { I18nCurrentLanguage, I18nLanguages, I18nProviderType, I18nType } from './I18nType';

const localStorageI18nLang = localStorage.getItem('i18nextLng');
const currentLocale = WiLocales.find(e => e.language === localStorageI18nLang) || WiLocales[0];

const initialValues: I18nType = {
    translate: (text: string) => text,
    translateWithParameters: (text: string, properties: any) => text,
    changeLanguage: (language: I18nLanguages, themeLanguage: Localization) => { },
    lang: {
        language: currentLocale.language,
        themeLanguage: currentLocale.themeLanguage
    }
}

const I18nContext = createContext<I18nType>(initialValues);

const I18nProvider: React.FC<I18nProviderType> = (props) => {

    const {
        resources,
        children
    } = props;

    const [lang, setLang] = React.useState<I18nCurrentLanguage>(initialValues.lang);

    const formatResources = (resources: Resource): Resource => {
        let result: Resource = {};

        Object.keys(resources).forEach(key => {
            result[key] = {
                'translations': resources[key]
            };
        })

        return result;
    }

    const translate = (text: string) => {
        return i18n.t(text);
    }

    const translateWithParameters = (text: string, properties: any) => {
        return i18n.t(text, properties);
    }


    const changeLanguageMemo = React.useMemo(
        () => ({
            changeLanguage: (language: I18nLanguages, themeLanguage: Localization) => {
                i18n.changeLanguage(language);
                setLang({language, themeLanguage});
            }
        }),
        []
    );

    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources: formatResources(resources),
            lng: lang.language,
            ns: ['translations'],
            defaultNS: 'translations'
        });

    return (
        <I18nContext.Provider value={{ translate, translateWithParameters, ...changeLanguageMemo, lang }}>
            {children}
        </I18nContext.Provider>
    );
}

export { I18nContext, I18nProvider };