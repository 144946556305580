import { useContext } from "react";
import { I18nContext } from "./../context/i18n/I18nContext";
import { I18nType } from "./../context/i18n/I18nType";


const useI18n = (): I18nType => {
    const context = useContext(I18nContext);

    if (context === undefined) {
        throw new Error('useColorMode must be used within a ColorModeProvider');
    }

    return context;
}


export default useI18n;