import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { useI18n } from '@wi-core/ui';

import ThemeLocalizationProps from './types/ThemeLocalizationProps';


const ThemeLocalizationProvider: React.FC<ThemeLocalizationProps> = ({ children }) => {
    const defaultTheme = useTheme();
    const { lang } = useI18n();

    const theme = createTheme(defaultTheme, lang.themeLanguage);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}


export default ThemeLocalizationProvider;