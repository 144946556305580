import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

import { SettingsProvider } from '@wi-themes/minimal-override';

import reportWebVitals from './reportWebVitals';
import App from './App';


ReactDOM.render(
	<React.StrictMode>
		<HelmetProvider>
			<SettingsProvider>
				<App />
			</SettingsProvider>
		</HelmetProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
