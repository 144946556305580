import DialogAdditionalTimeDataType from "../@types/DialogAdditionalTimeDataType";


const DefaultDialogAdditionalTimeValues: DialogAdditionalTimeDataType = {
    fullScreen: false,
    loading: false,
    businessHours: [],
    errorMessage: '',
    date: new Date(),
    currentTime: {
        disponibilityId: '',
        startAt: '',
        startAtHourString: '',
        startAtWithPreparationTime: '',
        startAtWithPreparationTimeHourString: '',
        endAt: '',
        endAtWithReposeTime: '',
        endAtWithSafeIntervalTime: '',
        requireAnesthesia: false,
        requireResponsible: false,
        tags: [],
        reservations: [],
        executionTime: 0,
        preparationTime: 0,
        reposeTime: 0,
        safeIntervalTime: 0,
        returnExecutionTime: 0
    },
    startTime: null,
    scrollTime: null,
    slotDuration: null
}

export default DefaultDialogAdditionalTimeValues;
