import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import RejectionFiles from '../rejection-files/RejectionFiles';
import BlockContent from '../block-content/BlockContent';
import MultiFilePreview from '../multi-file-preview/MultiFilePreview';
import UploadMultiFileProps from './types/UploadMultiFileProps';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
    outline: 'none',
    padding: theme.spacing(5, 1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.neutral,
    border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

// ----------------------------------------------------------------------

const UploadMultiFile: React.FC<UploadMultiFileProps> = ({
    error,
    showPreview = false,
    files,
    onRemove,
    onRemoveAll,
    helperText,
    sx,
    ...other
}) => {
    
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        ...other,
    });

    return (
        <Box sx={{ width: '100%', ...sx }}>
            <DropZoneStyle
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...((isDragReject || error) && {
                        color: 'error.main',
                        borderColor: 'error.light',
                        bgcolor: 'error.lighter',
                    }),
                    opacity: other.disabled ? 0.72 : 1,
                    '&:hover': !other.disabled  ? { opacity: 0.72, cursor: 'pointer' } : {}
                }}
            >
                <input {...getInputProps()} />

                <BlockContent />
            </DropZoneStyle>

            {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

            <MultiFilePreview
                files={files}
                showPreview={showPreview}
                onRemove={onRemove}
                onRemoveAll={onRemoveAll}
                disabled={other.disabled}
            />

            {helperText && helperText}
        </Box>
    );
}


export default UploadMultiFile;