

import Slider from 'react-slick';
import { useState, useRef, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Image from '@wi-themes/minimal/src/components/Image';

import WiViewImagesCarouselProps from './@types/WiViewImagesCarouselProps';
import CarouselArrowIndex from './components/carousel-arrow-index/CarouselArrowIndex';
import DownloadButton from './components/download-button/DownloadButton';

// ----------------------------------------------------------------------

const THUMB_SIZE = 64;

const RootStyle = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '0',
    right: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .slick-slide': {
        float: 'left',
        '&:focus': { outline: 'none' },
    },
}));

const WiViewImagesCarousel = (props: WiViewImagesCarouselProps) => {

    const {
        images
    } = props;

    const [currentIndex, setCurrentIndex] = useState(0);

    const [nav1, setNav1] = useState<Slider>();

    const [nav2, setNav2] = useState<Slider>();

    const slider1 = useRef<Slider | null>(null);

    const slider2 = useRef<Slider | null>(null);

    const settings1 = {
        dots: false,
        arrows: false,
        slidesToShow: 1,
        draggable: false,
        slidesToScroll: 1,
        adaptiveHeight: true,
        beforeChange: (current: number, next: number) => setCurrentIndex(next),
    };

    const settings2 = {
        dots: false,
        arrows: false,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,
        variableWidth: true,
        centerPadding: '0px',
        slidesToShow: images.length > 3 ? 3 : images.length,
    };

    useEffect(() => {
        if (slider1.current) {
            setNav1(slider1.current);
        }
        if (slider2.current) {
            setNav2(slider2.current);
        }
    }, []);

    const handlePrevious = () => {
        slider2.current?.slickPrev();
    };

    const handleNext = () => {
        slider2.current?.slickNext();
    };

    return (
        <RootStyle>
            <Box className='ImageContent'
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'calc(100% - 58px)',
                    height: 'calc(100% - 90px)'
                }}
            >
                <Box sx={{
                    zIndex: 0,
                    borderRadius: 2,
                    overflow: 'hidden',
                    width: '100%',
                    position: 'relative'
                }}>
                    <DownloadButton href={images[currentIndex].content} />
                    <Slider {...settings1} asNavFor={nav2} ref={slider1}>
                        {images.map((image) => (
                            <Image
                                key={image.key}
                                alt="large image"
                                src={image.content}
                                ratio="1/1"
                            />
                        ))}
                    </Slider>
                    <CarouselArrowIndex
                        index={currentIndex}
                        total={images.length}
                        onNext={handleNext}
                        onPrevious={handlePrevious}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    minHeight: '65px',
                    maxHeight: '65px',
                    maxWidth: '370px',
                    overflow: 'hidden',
                    mt: 3,
                    mx: 'auto',
                    '& .slick-current .isActive': { opacity: 1 },
                    ...(images.length === 1 && { maxWidth: THUMB_SIZE * 1 + 16 }),
                    ...(images.length === 2 && { maxWidth: THUMB_SIZE * 2 + 32 }),
                    ...(images.length === 3 && { maxWidth: THUMB_SIZE * 3 + 48 }),
                    ...(images.length === 4 && { maxWidth: THUMB_SIZE * 3 + 48 }),
                    ...(images.length >= 5 && { maxWidth: THUMB_SIZE * 6 }),
                    ...(images.length > 2 && {
                        position: 'relative',
                        '&:before, &:after': {
                            top: 0,
                            zIndex: 9,
                            content: "''",
                            height: '100%',
                            position: 'absolute',
                            width: (THUMB_SIZE * 2) / 3,
                            backgroundImage: (theme) => `linear-gradient(to left, ${alpha(theme.palette.background.paper, 0)} 0%, ${theme.palette.background.paper} 100%)`,
                        },
                        '&:after': { right: 0, transform: 'scaleX(-1)' },
                    }),
                }}
            >
                <Slider {...settings2} asNavFor={nav1} ref={slider2}>
                    {images.map((image, index) => (
                        <Box key={image.key} sx={{ px: 0.75 }}>
                            <Image
                                disabledEffect
                                alt="thumb image"
                                src={image.content}
                                sx={{
                                    width: THUMB_SIZE,
                                    height: THUMB_SIZE,
                                    borderRadius: 1.5,
                                    cursor: 'pointer',
                                    ...(currentIndex === index && {
                                        border: (theme) => `solid 3px ${theme.palette.primary.main}`,
                                    }),
                                }}
                            />
                        </Box>
                    ))}
                </Slider>
            </Box>
        </RootStyle>
    );
}

export default WiViewImagesCarousel;