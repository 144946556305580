import { WiLabelColor } from "@wi-themes/minimal-override";

import { EligibilityStatus } from "../@types";


const EligibilityStatusColor: { [key: string]: WiLabelColor } = {
    [EligibilityStatus.Pending]: 'warning',
    [EligibilityStatus.Authorized]: 'success',
    [EligibilityStatus.Processing]: 'warning',
    [EligibilityStatus.NotAuthorized]: 'warning',
    [EligibilityStatus.NotRequired]: 'primary'
};

export default EligibilityStatusColor;