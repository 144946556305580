import WiServiceCallContextType from '../@types/WiServiceCallContextType';

const InitialValues: WiServiceCallContextType = {
    open: false,
    ticket: null,
    countOpen: 0,
    messageError: '',
    serviceIsOpen: false,
    isActiveTicket: false,
    ticketCreatedAt: '',
    conteinServiceDesk: false,
    serviceDeskSelected: null,
    handleDone: () => {},
    handleNoShow: () => {},
    handleRecall: () => {},
    handleGetState: () => {},
    handleCallNext: () => {},
    onToggleSideBar: () => {},
    setServiceIsOpen: () => {},
    setIsActiveTicket: () => {},
    handleDoneAndCallNext: () => {},
    setConteinServiceDesk: () => {},
    handleNoShowAndCallNext: () => {},
    handleUnlinkServiceDesk: () => {},
    changeServiceDeskSelected: () => {},
};

export default InitialValues;
