import { Box, Stack, Typography, useTheme } from '@mui/material';
import Env from '@wi-config/env';
import EnvironmentType from '@wi-config/env/src/types/EnvironmentType';
import { useI18n } from '@wi-core/ui';

import wiCardHomologationi18n from './i18n';


const WiCardHomologation = () => {

    const theme = useTheme();
    const { lang } = useI18n();

    if (Env.type !== EnvironmentType.Homologation) {
        return <></>;
    }

    return (
        <Box sx={{
            position: 'fixed', 
            width: '200px',
            left: 'calc(50% - 100px)',
            borderRadius: '0 0 20px 20px',
            border: `1px solid ${theme.palette.primary.main}`,
            borderTop: 'none',
            bgcolor: theme.palette.primary.lighter,
            zIndex: theme.zIndex.appBar + 1000,
            color: theme.palette.primary.main
        }}>
            <Stack height='35px' direction="column" justifyContent="center" alignItems="center">
                <Typography variant='subtitle1'>
                    {wiCardHomologationi18n[lang.language].homologation || 'Homologation'}
                </Typography> 
            </Stack>
        </Box>
    )
}

export default WiCardHomologation;