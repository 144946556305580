import { enUS, ptBR } from '@mui/material/locale';
import { I18nLanguages } from "./../../context/i18n/I18nType";

import WiLocalesType from './WiLocalesType';

import us from "./../../assets/countries-flag/us.png"
import br from "./../../assets/countries-flag/br.png";


const WiLocales: WiLocalesType[] = [
    {
        key: 'br',
        name: 'Brazil',
        language: I18nLanguages.PtBr,
        themeLanguage: ptBR,
        languageLabel: 'Portuguese',
        icon: br
    },
    {
        key: 'us',
        name: 'United States',
        language: I18nLanguages.En,
        themeLanguage: enUS,
        languageLabel: 'English',
        icon: us
    }
]

export { WiLocales };