import WiPatientAppointmentHistoryDataType from '../@types/data-type/WiPatientAppointmentHistoryDataType';
import WiPatientAppointmentHistoryViewMode from '../@types/view-mode/WiPatientAppointmentHistoryViewMode';

const InitialValues: WiPatientAppointmentHistoryDataType = {
    viewMode: WiPatientAppointmentHistoryViewMode.List,
    selectedAppointment: null,
    fullScreen: false,
}

export default InitialValues;
