// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box, BoxProps } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
//
import Image from './components/image/Image';
import illustration_empty_content from '../../assets/illustration_empty_content.svg';
// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
	height: '100%',
	display: 'flex',
	textAlign: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	justifyContent: 'center',
	padding: theme.spacing(8, 2),
}));

// ----------------------------------------------------------------------

interface Props extends BoxProps {
	title: string;
	img?: string;
	description?: string;
	titleVariant?: Variant;
}

const WiEmptyContent = ({ title, description, img, titleVariant, ...other }: Props) => {
	return (
		<RootStyle {...other}>
			<Image
				disabledEffect
				visibleByDefault
				alt="empty content"
				src={illustration_empty_content}
				sx={{ height: 240, mb: 3 }}
			/>

			<Typography variant={titleVariant || 'subtitle1'} gutterBottom>
				{title}
			</Typography>

			{description && (
				<Typography variant="body2" sx={{ color: 'text.secondary' }}>
					{description}
				</Typography>
			)}
		</RootStyle>
	);
}

export default WiEmptyContent;