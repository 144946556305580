import { Box, List, ListSubheader, styled } from "@mui/material";
import { WiHasPermission, useI18n, useWiPermissionContext } from "@wi-core/ui";

import { NavListRoot } from "../nav-list/NavList";
import ListSubheaderStyleProps from "./@types/ListSubheaderStyleProps";
import NavSectionProps from "./@types/NavSectionProps";
import WiNavListProps from "../../../../@types/WiNavListProps";


// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
    <ListSubheader disableSticky disableGutters {...props} />
))<ListSubheaderStyleProps>(({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

export default function NavSection(props: NavSectionProps) {

    const {
        navConfig,
        isCollapse = false,
        ...other
    } = props;

    const { translate } = useI18n();
    const { hasPermission } = useWiPermissionContext();

    const renderList = (items: WiNavListProps[]) => {
        const result: JSX.Element[] = [];

        for (let item of items) {
            const currentRoles = item.roles || [];

            if (currentRoles.length > 0 && !hasPermission(currentRoles)) {
                continue;
            }

            result.push(<NavListRoot key={item.title} list={item} isCollapse={isCollapse} />);
        }
        

        return result;
    }

    const renderGroup = () => {
        const result: JSX.Element[] = [];
        
        for (let group of navConfig) {
            const list = renderList(group.items);

            if (list.length === 0) {
                continue;
            }

            result.push(
                <List key={group.subheader} disablePadding sx={{ px: 2 }}>
                    <ListSubheaderStyle
                        sx={{
                            ...(isCollapse && {
                                opacity: 0,
                            }),
                        }}
                    >
                        {translate(group.subheader)}
                    </ListSubheaderStyle>

                    {list}
                </List>
            );
        }

        return result;
    }

    return (
        <Box {...other}>
            {renderGroup()}
        </Box>
    );
}
