
import { Menu, Popover } from "@mui/material";
import WiMenuPopoverProps from "./@types/WiMenuPopoverProps";


const ARROW_POSITION = {
    //top
    'top-left': { top: 0, left: '15px' },
    'top-center': { top: 0, left: 0, right: 0, margin: 'auto' },
    'top-right': { top: 0, right: '15px' },
    //Bottom
    'bottom-left': { bottom: '-10px', left: '15px' },
    'bottom-center': { bottom: '-10px', left: 0, right: 0, margin: 'auto' },
    'bottom-right': { bottom: '-10px', right: '15px' },
    //Left
    'left-top': { left: '-4px', top: '15px' },
    'left-center': { left: '-4px', top: 0, bottom: 0, margin: 'auto' },
    'left-bottom': { left: '-4px', bottom: '15px' },
    //Right
    'right-top': { right: '-4px', top: '15px' },
    'right-center': { right: '-4px', top: 0, bottom: 0, margin: 'auto' },
    'right-bottom': { right: '-4px', bottom: '15px' }
}

const MARGIN_BY_ARROW_POSITION = {
    //top
    'top-left': { mt: '12px' },
    'top-center': { mt: '12px' },
    'top-right': { mt: '12px' },
    //Bottom
    'bottom-left': { mt: '-12px' },
    'bottom-center': { mt: '-12px' },
    'bottom-right': { mt: '-12px' },
    //Left
    'left-top': { ml: '12px', mt: '4px' },
    'left-center': { ml: '12px', mt: '4px' },
    'left-bottom': { ml: '12px', mt: '4px' },
    //Right
    'right-top': { ml: '-12px', mt: '4px' },
    'right-center': { ml: '-12px', mt: '4px' },
    'right-bottom': { ml: '-12px', mt: '4px' }
}

const WiMenuPopover = (props: WiMenuPopoverProps) => {

    const {
        anchorEl,
        onClose,
        children,
        sx,
        anchorOrigin,
        transformOrigin,
        arrowPosition,
    } = props;

    const open = Boolean(anchorEl);

    return (
        <Popover
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={onClose}
            onClick={onClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    p: '8px',
                    overflow: 'visible',
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        ...ARROW_POSITION[arrowPosition]
                    },
                    ...MARGIN_BY_ARROW_POSITION[arrowPosition]
                },
            }}
            sx={{
                borderRadius: '12px',
                ...sx
            }}
            transformOrigin={transformOrigin || { horizontal: 'right', vertical: 'top' }}
            anchorOrigin={anchorOrigin || { horizontal: 'right', vertical: 'bottom' }}
        >
            {children}
        </Popover>
    );
}

export default WiMenuPopover;