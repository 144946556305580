import { styled, Typography } from "@mui/material";
import WiTitleProps from "./types/WiTitleProps";


const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));


const WiTitle: React.FC<WiTitleProps> = (props) => {

    const {
        children,
        sx
    } = props;

    return <LabelStyle sx={{...sx}}>{children}</LabelStyle>
}

export default WiTitle;