import { createContext, useState } from 'react';
import { ThemeMode } from '@wi-themes/minimal/src/components/settings/type';
import useLocalStorage from '@wi-themes/minimal/src/hooks/useLocalStorage';

import DefaultSettings from './configs/DefaultSettings';

import SettingsContextProps from './@types/SettingsContextProps';
import SettingsProviderProps from './@types/SettingsProviderProps';

// ----------------------------------------------------------------------

const initialState: SettingsContextProps = {
	...DefaultSettings,
	onChangeMode: () => { },
	onToggleMode: () => { },
	onToggleStretch: () => { },
	onResetSetting: () => { },
	onToggleSetting: () => { },
	open: false
};

const SettingsContext = createContext(initialState);

const SettingsProvider = (props: SettingsProviderProps) => {

	const { 
		children 
	} = props;

	const [open, setOpen] = useState<boolean>(false);
	const [settings, setSettings] = useLocalStorage('wi-settings', {
		themeMode: initialState.themeMode,
		themeStretch: initialState.themeStretch
	});

	const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeMode: (event.target as HTMLInputElement).value as ThemeMode,
		});
	};

	const onToggleMode = () => {
		setSettings({
			...settings,
			themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
		});
	};

	const onToggleStretch = () => {
		setSettings({
			...settings,
			themeStretch: !settings.themeStretch,
		});
	};

	const onResetSetting = () => {
		setSettings({
			themeMode: initialState.themeMode,
			themeStretch: initialState.themeStretch
		});
	};

	const onToggleSetting = () => {
		setOpen(!open);
	}

	return (
		<SettingsContext.Provider
			value={{
				...settings,
				onChangeMode,
				onToggleMode,
				onToggleStretch,
				onResetSetting,
				onToggleSetting,
				open
			}}
		>
			{children}
		</SettingsContext.Provider>
	);
}

export { 
	SettingsProvider, 
	SettingsContext 
};
