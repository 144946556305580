import { useState } from "react";
import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";

import Navbar from "../components/navbar/Navbar";

import { HEADER, NAVBAR } from "../../config";
import useCollapseDrawer from "@wi-themes/minimal/src/hooks/useCollapseDrawer";
import WiDashboardLayoutProps from "./types/WiDashboardLayoutProps";
import { styled } from "@mui/material/styles";
import Header from "../components/header/Header";

type MainStyleProps = {
    collapseClick: boolean;
};

const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
    flexGrow: 1,
    paddingTop: HEADER.MOBILE_HEIGHT + 24,
    paddingBottom: HEADER.MOBILE_HEIGHT + 24,
    backgroundColor: theme.palette.grey[500_12],
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
        paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
        transition: theme.transitions.create('margin-left', {
            duration: theme.transitions.duration.shorter,
        }),
        ...(collapseClick && {
            marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
        }),
        '& > .MuiBox-root': {
            height: '100%'
        },
        '& > .MuiBox-root > .MuiContainer-root': {
            height: '100%'
        }
    },
}));

const WiDashboardLayout: React.FC<WiDashboardLayoutProps> = (props) => {

    const {
        navConfig,
        hideQuickAccessMenu
    } = props;

    const { collapseClick, isCollapse } = useCollapseDrawer();

    const [open, setOpen] = useState(false);

    return (
        <Box sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
        }}>
            <Header 
                isCollapse={isCollapse} 
                onOpenSidebar={() => setOpen(true)}
                hideQuickAccessMenu={hideQuickAccessMenu}
            />
            <Navbar
                isOpenSidebar={open}
                onCloseSidebar={() => setOpen(false)}
                config={navConfig.config}
                appBanner={navConfig.appBanner}
            />

            <MainStyle collapseClick={collapseClick}>
                <Outlet />
            </MainStyle>
        </Box>
    );
}


export default WiDashboardLayout;
