import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RegisterType, StatusFilter } from "@wi-core/shared-blocks";

import AdvancedFilter from "../../../../@types/AdvancedFilter";


const defaultValues: AdvancedFilter = {
    organizationalUnit: null,
    motherName: '',
    status: StatusFilter.Any,
    type: RegisterType.Any,
    document: ''
};

const usePersonFilterSidebar = () => {

    const [open, setOpen] = useState<boolean>(false);
    const [currentFilter, setCurrentFilter] = useState<AdvancedFilter | null>(null); 

    const methods = useForm({ defaultValues });
    const { handleSubmit, reset } = methods;

    useEffect(() => {
        if(!open) return;

        reset(currentFilter || defaultValues);
    }, [open]);

    const handleReset = () => {
        reset(defaultValues);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    return {
        methods,
        open,
        handleOpen,
        handleClose,
        handleReset,
        handleSubmit,
        setCurrentFilter
    }
}

export default usePersonFilterSidebar;