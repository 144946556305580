import React from 'react';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import Env from '@wi-config/env';
import { useAuth } from "react-oidc-context";
import { useI18n, WiLabelVersion } from '@wi-core/ui';
import { WiLoadingScreen, WiLogo, WiSelectLanguage } from '@wi-themes/minimal-override';

import './LoginPage.less';

const LoginPage = () => {

    const auth = useAuth();
    const { translate } = useI18n();

    if (auth.isAuthenticated) {
        window.location.pathname = Env.portalPath;
    }

    if (auth.isLoading || auth.isAuthenticated) {
        return <WiLoadingScreen />;
    }

    return (
        <>
            <Box
                className="container"
                display="flex"
                justifyContent="center"
                height="100vh"
                sx={{ alignItems: 'center' }}
            >
                <Paper elevation={3} sx={{ p: 3, maxWidth: 445 }}>
                    <Stack spacing={2}>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <WiLogo disabledLink={true} sx={{ width: 180, height: 80 }} />

                            <WiSelectLanguage />
                        </Box>

                        <Typography
                            variant="h5"
                            className="wellcome-title"
                            sx={{ pt: 3, color: 'primary.main' }}
                        >
                            {translate('Hello!')}
                        </Typography>
                        <Typography
                            variant="h5"
                            className="wellcome-subtitle"
                            sx={{ color: 'primary.main' }}
                        >
                            {translate('Welcome back.')}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ py: 3 }}>
                            {translate('How are we going to transform the world today?')} {translate('Please login to access the platform.')}
                        </Typography>
                        <Button
                            variant="contained"
                            size="medium"
                            className="signin"
                            onClick={() => auth.signinRedirect()}
                        >
                            {translate('Access')}
                        </Button>
                    </Stack>
                </Paper>
            </Box>

            <WiLabelVersion
                sx={{
                    position: 'absolute',
                    bottom: { md: '24px', sm: '16px', xs: '16px' },
                    left: { md: '40px', sm: '24px', xs: '24px' }
                }}
            />
        </>
    );
};

export default LoginPage;
