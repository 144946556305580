import PatientAppointmentHistoryFilter from '../@types/PatientAppointmentHistoryFilter';

const startAt = new Date();
startAt.setDate(startAt.getDate() - 30);

const InitialFilter: PatientAppointmentHistoryFilter = {
    startAt: startAt,
    endAt: new Date()
};

export default InitialFilter;
