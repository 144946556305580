import { WiLabelColor } from "@wi-themes/minimal-override";

import DuplicateRelevance from "../@types/duplicate-relevance/DuplicateRelevance";


const DuplicateRelevanceColor: { [key: string]: WiLabelColor } = {
    [DuplicateRelevance.VeryHigh]: 'error',
    [DuplicateRelevance.High]: 'error',
    [DuplicateRelevance.Medium]: 'warning',
    [DuplicateRelevance.Low]: 'info',
    [DuplicateRelevance.VeryLow]: 'info',
}

export default DuplicateRelevanceColor;