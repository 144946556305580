import { alpha, styled } from "@mui/material";
import Iconify from "@wi-themes/minimal/src/components/Iconify";
import WiAppLogoProps from "./types/WiAppLogoProps";



// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    minWidth: '40px',
    minHeight: '40px',
    justifyContent: 'center'
}));

// ----------------------------------------------------------------------



const WiAppLogo: React.FC<WiAppLogoProps> = (props) => {

    const {
        icon,
        color,
        width,
        height,
        sx
    } = props;


    return (
        <IconWrapperStyle
            sx={{
                color: (theme) => color || theme.palette.primary.dark,
                backgroundImage: (theme) =>
                    `linear-gradient(135deg, 
                    ${alpha(color || theme.palette.primary.dark, 0)} 0%, 
                    ${alpha(color || theme.palette.primary.dark, 0.24)} 100%)`,
                ...sx
            }}
        >
            <Iconify icon={icon} width={width || 24} height={height || 24} />
        </IconWrapperStyle>
    );
}

export default WiAppLogo;