
import en from "./resources/en.json";
import pt_br from "./resources/pt_br.json";


const commoni18n = {
    en: {
        ...en
    },
    pt_br: {
        ...pt_br
    }
};

export default commoni18n;
