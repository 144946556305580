import { ExceptionCodes } from "@wi-core/shared-blocks";

const EXCEPTION_CODE_UPDATE_PERSON_BENEFICIARY_CARD_MISSING = 'PE UC002 0021';

const personExceptionCodes: ExceptionCodes = {
    //create_person
    "PE UC001 0001": "create_person.invalid_license_key",
    "PE UC001 0002": "create_person.user_not_authorized",
    "PE UC001 0003": "create_person.invalid_name",
    "PE UC001 0004": "create_person.invalid_last_name",
    "PE UC001 0005": "create_person.invalid_image",
    "PE UC001 0006": "create_person.invalid_color",
    "PE UC001 0007": "create_person.invalid_genre",
    "PE UC001 0008": "create_person.user_same_document_already_exists",
    "PE UC001 0009": "create_person.user_same_name_mother_name_birth",
    "PE UC001 0010": "create_person.invalid_mother_name",
    "PE UC001 0011": "create_person.invalid_nationality",
    "PE UC001 0012": "create_person.invalid_city",
    "PE UC001 0013": "create_person.invalid_health_plan",
    "PE UC001 0014": "create_person.invalid_address",
    "PE UC001 0015": "create_person.invalid_contact",
    "PE UC001 0016": "create_person.invalid_document",
    "PE UC001 0017": "create_person.invalid_format",
    "PE UC001 0018": "create_person.invalid_email",
    "PE UC001 0019": "create_person.birthday_less_than_the_minimum_supported_date",
    "PE UC001 0020": "create_person.invalid_dependent",
    "PE UC001 0021": "create_person.birthday_greater_than_the_maximum_supported_date",
    "PE UC001 0022": "create_person.external_user_not_found",
    "PE UC001 0023": "create_person.invalid_external_user_first_name",
    "PE UC001 0024": "create_person.invalid_external_user_last_name",
    "PE UC001 0025": "create_person.invalid_external_user_email",
    "PE UC001 0026": "create_person.there_is_already_an_external_user_with_this_email",
    "PE UC001 0027": "create_person.person_invalid_for_linking_with_external_user",

    //update_person
    "PE UC002 0001": "update_person.invalid_license_key",
    "PE UC002 0002": "update_person.user_not_authorized",
    "PE UC002 0003": "update_person.invalid_name",
    "PE UC002 0004": "update_person.invalid_last_name",
    "PE UC002 0005": "update_person.invalid_image",
    "PE UC002 0006": "update_person.invalid_color",
    "PE UC002 0007": "update_person.invalid_genre",
    "PE UC002 0008": "update_person.user_same_document_already_exists",
    "PE UC002 0009": "update_person.user_same_name_mother_name_birth",
    "PE UC002 0010": "update_person.invalid_mother_name",
    "PE UC002 0011": "update_person.invalid_nationality",
    "PE UC002 0012": "update_person.invalid_city",
    "PE UC002 0013": "update_person.invalid_country",
    "PE UC002 0014": "update_person.invalid_health_plan",
    "PE UC002 0015": "update_person.invalid_contact",
    "PE UC002 0016": "update_person.invalid_email",
    "PE UC002 0017": "update_person.person_not_found",
    "PE UC002 0018": "update_person.birthday_less_than_the_minimum_supported_date",
    "PE UC002 0019": "update_person.invalid_dependent",
    "PE UC002 0020": "update_person.invalid_unification_of_people",
    [ EXCEPTION_CODE_UPDATE_PERSON_BENEFICIARY_CARD_MISSING ]: "update_person.beneficiary_card_missing",
    "PE UC002 0022": "update_person.birthday_greater_than_the_maximum_supported_date",
    "PE UC002 0023": "update_person.external_user_not_found",
    "PE UC002 0024": "update_person.invalid_external_user_first_name",
    "PE UC002 0025": "update_person.invalid_external_user_last_name",
    "PE UC002 0026": "update_person.invalid_external_user_email",
    "PE UC002 0027": "update_person.there_is_already_an_external_user_with_this_email",
    "PE UC002 0028": "update_person.person_invalid_for_linking_with_external_user",

    //get_person
    "PE UC003 0001": "get_person.person_not_found",
    "PE UC003 0002": "get_person.user_not_authorized_view",

    //archive_person
    "PE UC005 0001": "archive_person.user_not_authorized_archive",
    "PE UC005 0002": "archive_person.person_not_found",
    "PE UC005 0003": "archive_person.invalid_person_name",
    "PE UC005 0004": "archive_person.person_already_archive",

    //unarchive_person
    "PE UC006 0001": "unarchive_person.user_not_authorized_unarchive",
    "PE UC006 0002": "unarchive_person.person_not_found",
    "PE UC006 0003": "unarchive_person.invalid_person_name",
    "PE UC006 0004": "unarchive_person.person_already_unarchive",

    //import_person
    "PE UC007 0001": "import_person.invalid_license_key",
    "PE UC007 0002": "import_person.user_not_authorized_unarchive",
    "PE UC007 0003": "import_person.invalid_name",
    "PE UC007 0004": "import_person.invalid_last_name",
    "PE UC007 0005": "import_person.invalid_color",
    "PE UC007 0006": "import_person.invalid_genre",
    "PE UC007 0007": "import_person.user_same_document_already_exists",
    "PE UC007 0008": "import_person.user_same_name_mother_name_birth",
    "PE UC007 0009": "import_person.invalid_mother_name",
    "PE UC007 0010": "import_person.invalid_nationality",
    "PE UC007 0011": "import_person.invalid_city",
    "PE UC007 0012": "import_person.invalid_health_plan",
    "PE UC007 0013": "import_person.invalid_contact",
    "PE UC007 0014": "import_person.invalid_email",
    "PE UC007 0015": "import_person.invalid_organizational_unit",
    "PE UC007 0016": "import_person.invalid_integration_code",
    "PE UC007 0017": "import_person.invalid_integration_external_reference",
    "PE UC007 0018": "import_person.invalid_integration_identification",
    "PE UC007 0019": "import_person.birthday_less_than_the_minimum_supported_date",
    "PE UC007 0020": "import_person.birthday_greater_than_the_maximum_supported_date",

    //update_person_health_plan
    "PE UC011 0001": "update_person_health_plan.user_not_authorized_update",
    "PE UC011 0002": "update_person_health_plan.invalid_health_plan",
    "PE UC011 0003": "update_person_health_plan.person_not_found",

    //get_person_health_plan
    "PE UC012 0001": "get_person_health_plan.person_not_found",
    "PE UC012 0002": "get_person_health_plan.user_not_authorized_view",

    //update_person_contact
    "PE UC013 0001": "update_person_contact.user_not_authorized_view",
    "PE UC013 0002": "update_person_contact.invalid_contact",
    "PE UC013 0003": "update_person_contact.invalid_email",
    "PE UC013 0004": "update_person_contact.person_not_found",

    //get_person_contact
    "PE UC014 0001": "get_person_contact.person_not_found",
    "PE UC014 0002": "get_person_contact.user_not_authorized_view",

    //import_person_list
    "PE UC016 0002": "import_person_list.user_not_authorized",
    "PE UC016 0022": "import_person_list.all_items_contain_errors",
    "PE UC016 0023": "import_person_list.one_or_more_items_contain_errors",

    //create_person_by_external_reference
    "PE UC018 0001": "create_person_by_external_reference.invalid_license_key",
    "PE UC018 0002": "create_person_by_external_reference.user_not_authorized",
    "PE UC018 0003": "create_person_by_external_reference.invalid_name",
    "PE UC018 0004": "create_person_by_external_reference.invalid_last_name",
    "PE UC018 0005": "create_person_by_external_reference.invalid_image",
    "PE UC018 0006": "create_person_by_external_reference.invalid_color",
    "PE UC018 0007": "create_person_by_external_reference.invalid_genre",
    "PE UC018 0008": "create_person_by_external_reference.user_same_document_already_exists",
    "PE UC018 0009": "create_person_by_external_reference.user_same_name_mother_name_birth",
    "PE UC018 0010": "create_person_by_external_reference.invalid_mother_name",
    "PE UC018 0011": "create_person_by_external_reference.invalid_nationality",
    "PE UC018 0012": "create_person_by_external_reference.invalid_city",
    "PE UC018 0013": "create_person_by_external_reference.invalid_health_plan",
    "PE UC018 0014": "create_person_by_external_reference.invalid_contact",
    "PE UC018 0015": "create_person_by_external_reference.invalid_format",
    "PE UC018 0016": "create_person_by_external_reference.invalid_email",
    "PE UC018 0017": "create_person_by_external_reference.birthday_less_than_the_minimum_supported_date",
    "PE UC018 0018": "create_person_by_external_reference.invalid_dependent",
    "PE UC018 0019": "create_person_by_external_reference.birthday_greater_than_the_maximum_supported_date",

    //update_person_integration_link
    "PE UC019 0001": "update_person_integration_link.invalid_license_key",
    "PE UC019 0002": "update_person_integration_link.user_not_authorized",
    "PE UC019 0003": "update_person_integration_link.person_not_found",
    "PE UC019 0004": "update_person_integration_link.person_of_reference_type_cannot_contain_integration_links",

    //mark_duplicate_as_rejected
    "PE UC021 0001": "mark_duplicate_as_rejected.person_not_found",

    //create_person_inconsistencies
    "PE UC022 0001": "create_person_inconsistencies.invalid_first_name",
    "PE UC022 0002": "create_person_inconsistencies.invalid_last_name",
    "PE UC022 0003": "create_person_inconsistencies.invalid_gender",
    "PE UC022 0004": "create_person_inconsistencies.invalid_health_plan",
    "PE UC022 0005": "create_person_inconsistencies.invalid_contact",
    "PE UC022 0006": "create_person_inconsistencies.invalid_document",
    "PE UC022 0007": "create_person_inconsistencies.birthday_less_than_the_minimum_supported_date",
    "PE UC022 0008": "create_person_inconsistencies.birthday_greater_than_the_maximum_supported_date",

    //get_public_user_by_id
    "PE UC101 0001": "get_public_user_by_id.external_user_not_found",

    //update_public_user
    "PE UC105 0001": "update_public_user.external_user_not_found",
    "PE UC105 0002": "update_public_user.invalid_first_name",
    "PE UC105 0003": "update_public_user.invalid_last_name",
    "PE UC105 0004": "update_public_user.invalid_email",
    "PE UC105 0005": "update_public_user.there_is_already_an_external_user_with_this_email",

    //send_change_password_email
    "PE UC107 0001": "send_change_password_email.external_user_not_found",
    "PE UC107 0002": "send_change_password_email.invalid_email",

    //send_verification_email
    "PE UC108 0001": "send_verification_email.external_user_not_found",
    "PE UC108 0002": "send_verification_email.invalid_email",
    "PE UC108 0003": "send_verification_email.email_is_already_confirmed",

    //archive_public_user
    "PE UC109 0001": "archive_public_user.external_user_not_found",
    "PE UC109 0002": "archive_public_user.invalid_email",
    "PE UC109 0003": "archive_public_user.external_user_is_already_archived",

    //unarchive_public_user
    "PE UC110 0001": "unarchive_public_user.external_user_not_found",
    "PE UC110 0002": "unarchive_public_user.invalid_email",
    "PE UC110 0003": "unarchive_public_user.external_user_is_already_unarchived",

    //link_public_user_with_person
    "PE UC111 0001": "link_public_user_with_person.external_user_not_found",
    "PE UC111 0002": "link_public_user_with_person.invalid_license",
    "PE UC111 0003": "link_public_user_with_person.invalid_person",
    "PE UC111 0004": "link_public_user_with_person.duplicate_license_key",

    //get_public_user
    "PE UC201 0001": "get_public_user.external_user_not_found",
}

export default {
    ...personExceptionCodes
};

export {
    EXCEPTION_CODE_UPDATE_PERSON_BENEFICIARY_CARD_MISSING
}