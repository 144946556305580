import { ReservationStatus } from '@wi-core/shared-blocks';
import { WiLabelColor } from "@wi-themes/minimal-override";



const ReservationStatusColor: { [key: string]: WiLabelColor } = {
    [ReservationStatus.Reservation]: 'success',
    [ReservationStatus.Cancelled]: 'error',
    [ReservationStatus.Transfer]: 'info',
    [ReservationStatus.Scheduling]: 'info',
    [ReservationStatus.Scheduled]: 'info',
    [ReservationStatus.Expired]: 'default',
};

export default ReservationStatusColor;
