
import { commoni18n } from "@wi-core/ui";

import en from "./resources/en.json";
import pt_br from "./resources/pt_br.json";


const i18n = {
    en: {
        ...en,
        ...commoni18n.en
    },
    pt_br: {
        ...pt_br,
        ...commoni18n.pt_br
    }
};

export default i18n;