
import './../../../utils/highlight';
import ReactQuill from 'react-quill';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import EditorToolbar, { formats, redoChange, undoChange } from './EditorToolbar';
import { WiEditorNoFormProps } from './@types/WiEditorNoFormProps';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	border: `solid 1px ${theme.palette.grey[500_32]}`,
	'& .ql-container.ql-snow': {
		borderColor: 'transparent',
		...theme.typography.body1,
		fontFamily: theme.typography.fontFamily,
	},
	'& .ql-editor': {
		minHeight: 200,
		'&.ql-blank::before': {
			fontStyle: 'normal',
			color: theme.palette.text.disabled,
		},
		'& pre.ql-syntax': {
			...theme.typography.body2,
			padding: theme.spacing(2),
			borderRadius: theme.shape.borderRadius,
			backgroundColor: theme.palette.grey[900],
		},
	},
}));

// ----------------------------------------------------------------------

export default function WiEditorNoForm({
	id = 'minimal-quill',
	error,
	value,
	onChange,
	simple = false,
	helperText,
	sx,
    placeholder,
	disabled,
	...other
}: WiEditorNoFormProps) {
	const modules = {
		toolbar: {
			container: `#${id}`,
			handlers: {
				undo: undoChange,
				redo: redoChange,
			},
		},
		history: {
			delay: 500,
			maxStack: 100,
			userOnly: true,
		},
		syntax: true,
		clipboard: {
			matchVisual: false,
		},
	};

	return (
		<div>
			<RootStyle
				sx={{
					opacity: disabled ? 0.7 : 1,
					...(error && {
						border: (theme) => `solid 1px ${theme.palette.error.main}`,
					}),
					...sx,
				}}
			>
				<EditorToolbar id={id} isSimple={simple} />
				<ReactQuill
					value={value}
					onChange={onChange}
					modules={modules}
					formats={formats}
					placeholder={placeholder}
					{
						...other
					}
					readOnly={disabled}
				/>
			</RootStyle>

			{helperText && helperText}
		</div>
	);
}
