import { ListPrinterResponse, PrinterState } from "../@types";

const DefaultValuesListPrinterResponse: ListPrinterResponse = {
    name: '',
    description: '',
    location: '',
    makeAndModel: '',
    state: PrinterState.UNKNOWN
}

export default DefaultValuesListPrinterResponse;