import WiPermissionContextType from '../@types/WiPermissionContextType';


const InitialValues: WiPermissionContextType = {
    getCurrentUser: () => null,
    hasPermission: (roles: string[]) => false,
    hasPermissionLicenses: (licensesKey: string[], roles: string[]) => false,
    hasPermissionOrganizationalUnits: (organizationalUnitsKey: string[], roles: string[]) => false,
    getCurrentUserForAutoComplete: () => null,
    getLicensesByRoles: (roles: string[]) => [],
    getOrganizationalUnitsByLicenseKeyAndRoles: (licenseKey: string, roles: string[]) => []
};

export default InitialValues;
