import React from 'react';
import { AuthProvider } from "react-oidc-context";
import { I18nProvider } from '@wi-core/ui';
import oidcConfig from '@wi-config/oidc';
import { ProgressBarStyle } from '@wi-themes/minimal/src/components/ProgressBar';
import MotionLazyContainer from '@wi-themes/minimal/src/components/animate/MotionLazyContainer';
import { ThemeProvider, ThemeLocalizationProvider, DateLocalizationProvider } from '@wi-themes/minimal-override';

import i18n from './i18n';
import colorScheme from './color-scheme';
import LoginPage from './features/login/LoginPage';


function App() {
    return (
        <AuthProvider {...oidcConfig}>
            <I18nProvider resources={i18n}>
                <ThemeProvider colorScheme={colorScheme}>
                    <ThemeLocalizationProvider>
                        <DateLocalizationProvider>
                            <MotionLazyContainer>
                                <ProgressBarStyle />
                                <LoginPage />
                            </MotionLazyContainer>
                        </DateLocalizationProvider>
                    </ThemeLocalizationProvider>
                </ThemeProvider>
            </I18nProvider>
        </AuthProvider>
    );
}

export default App;
