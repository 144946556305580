import React from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar } from '@mui/material';
import { WiIsVisible } from '@wi-core/ui';
import { ROLES } from '@wi-core/shared-blocks';
import useOffSetTop from '@wi-themes/minimal/src/hooks/useOffSetTop';
import useResponsive from '@wi-themes/minimal/src/hooks/useResponsive';
import cssStyles from '@wi-themes/minimal/src/utils/cssStyles';
import { IconButtonAnimate } from '@wi-themes/minimal/src/components/animate';
import Iconify from '@wi-themes/minimal/src/components/Iconify';
import { WiHasPermission, WiReservationListButton, WiSelectPrintProfile, WiServiceCallButton } from '@wi-core/ui';

import { HEADER, NAVBAR } from '../../../config';
import { WiLogo, WiQuickAccessMenu, WiSelectLanguage } from '../../../components';
import AccountPopover from './components/AccountPopover';

type RootStyleProps = {
    isCollapse: boolean;
    isOffset: boolean;
    verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
    shouldForwardProp: prop => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout'
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    height: HEADER.MOBILE_HEIGHT,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shorter
    }),
    [theme.breakpoints.up('lg')]: {
        height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
        ...(isCollapse && {
            width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`
        }),
        ...(isOffset && {
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT
        }),
        ...(verticalLayout && {
            width: '100%',
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
            backgroundColor: theme.palette.background.default
        })
    }
}));

type Props = {
    onOpenSidebar?: VoidFunction;
    isCollapse?: boolean;
    verticalLayout?: boolean;
    hideQuickAccessMenu?: boolean;
    hideModulesMenuButton?: boolean;
};

export default function DashboardHeader({
    onOpenSidebar,
    isCollapse = false,
    verticalLayout = false,
    hideQuickAccessMenu = false,
    hideModulesMenuButton = false
}: Props) {
    const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

    const isDesktop = useResponsive('up', 'lg');

    const location = useLocation();

    return (
        <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
            <Toolbar
                sx={{
                    minHeight: '100% !important',
                    px: { lg: 5 }
                }}
            >
                {isDesktop && verticalLayout && <WiLogo sx={{ mr: 2.5 }} />}

                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    {!isDesktop && !hideModulesMenuButton && (
                        <IconButtonAnimate onClick={onOpenSidebar} sx={{ color: 'text.primary' }}>
                            <Iconify icon="eva:menu-2-fill" color="text.secondary" />
                        </IconButtonAnimate>
                    )}
                    {!hideQuickAccessMenu && <WiQuickAccessMenu />}
                </Stack>

                <Box sx={{ flexGrow: 1 }} />

                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    <WiSelectPrintProfile />

                    <WiIsVisible when={location.pathname.includes('/schedule')}>
                        <WiHasPermission roles={[ROLES.RESERVATION_VIEWER, ROLES.RESERVATION_MANAGER]}>
                            <WiReservationListButton />
                        </WiHasPermission>
                    </WiIsVisible>

                    <WiServiceCallButton />

                    <WiSelectLanguage />
                    
                    <AccountPopover />
                </Stack>
            </Toolbar>
        </RootStyle>
    );
}
