import RegisterType from "../@types/register-type/RegisterType";
import SearchPersonForAppointmentAndAttendanceResponse from "../@types/search-person-for-appointment-and-attendance/SearchPersonForAppointmentAndAttendanceResponse";


const DefaultValuesSearchPersonForAppointmentAndAttendance: SearchPersonForAppointmentAndAttendanceResponse = {
    id: '',
    licenseKey: '',
    licenseName: '',
    firstName: '',
    lastName: '',
    name: '',
    avatar: '',
    color: '',
    hasNoEmail: false,
    motherName: '',
    birthDate: '',
    registerType: RegisterType.Main,
    documents: [],
    contacts: [],
    externalReferences: [],
    integrationLinks: [],
    requireResponsibleByOrganizationalUnitKey: {},
    anyOrganizationalUnitRequireResponsible: false,
    verified: false,
    healthCards: []
}

export default DefaultValuesSearchPersonForAppointmentAndAttendance;
