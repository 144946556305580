import { useMemo, ReactNode } from 'react';

// @mui
import { CssBaseline } from '@mui/material';
import {
	createTheme,
	ThemeOptions,
	ThemeProvider as MUIThemeProvider,
	StyledEngineProvider,
} from '@mui/material/styles';

//\
import defaultPalette from '@wi-themes/minimal/src/theme/palette';
import typography from '@wi-themes/minimal/src/theme/typography';
import breakpoints from '@wi-themes/minimal/src/theme/breakpoints';
import componentsOverride from '@wi-themes/minimal/src/theme/overrides';
import Env from '@wi-config/env';
import EnvironmentType from '@wi-config/env/src/types/EnvironmentType';

import createMuiShadows from './utils/createMuiShadows';
import createCustomMuiShadows from './utils/createCustomMuiShadows';
import createMuiPalette from './utils/createMuiPalette';
import useSettings from '../../hooks/use-settings/useSettings';
import { WiCardHomologation } from '../../components';
import { ColorScheme } from './types/color-scheme';

// scroll bar
import 'simplebar/src/simplebar.css';

// editor
import 'react-quill/dist/quill.snow.css';
import homologationPalette from './utils/homologationPalette';
import CustomGlobalStyles from './styles/CustomGlobalStyles';


// ----------------------------------------------------------------------
type Props = {
	children: ReactNode;
	colorScheme?: ColorScheme
};

export default function ThemeProvider({ children, colorScheme }: Props) {

	const { themeMode } = useSettings();
	const isLight = themeMode === 'light';

	let currentPalette = defaultPalette;

	if(colorScheme) {
		currentPalette = createMuiPalette(colorScheme);
	}

	if(Env.type === EnvironmentType.Homologation) {
		currentPalette = createMuiPalette(homologationPalette);
	}

	const themeOptions: ThemeOptions = useMemo(
		() => ({
			palette: isLight ? currentPalette.light : currentPalette.dark,
			typography,
			breakpoints,
			shape: { borderRadius: 8 },
			shadows: isLight ? createMuiShadows(currentPalette).light : createMuiShadows(currentPalette).dark,
			customShadows: isLight ? createCustomMuiShadows(currentPalette).light : createCustomMuiShadows(currentPalette).dark,
		}),
		[isLight]
	);

	const theme = createTheme(themeOptions);
	theme.components = componentsOverride(theme);

	return (
		<StyledEngineProvider injectFirst>
			<MUIThemeProvider theme={theme}>
				<WiCardHomologation/>
				<CssBaseline />
				<CustomGlobalStyles />
				{children}
			</MUIThemeProvider>
		</StyledEngineProvider>
	);
}
