
import React from "react";
import { IMaskInput } from "react-imask";
import WiTextMaskProps from "./@types/WiTextMaskProps";


const WiTextMask = React.forwardRef<HTMLElement, WiTextMaskProps> (

    function TextMaskCustom(props, ref) {

        const {
            onChange,
            mask,
            definitions,
            onAccept,
            ...other
        } = props;


        const addMask = (mask: any) => {
            if (typeof mask === 'string') {
                return [{
                    mask: mask,
                    definitions: definitions
                }];
            }

            if(Array.isArray(mask)){
                return mask.map((m) => {
                    return {
                        mask: m,
                        definitions: definitions
                    };
                });
            }

            return mask;
        };


        return (
            <IMaskInput
                {...other}
                mask={addMask(mask)}
                definitions={definitions}
                inputRef={ref as any}
                onChange={onChange}
                onAccept={(value: any) => {
                    onChange({ target: { name: props.name, value } });

                    if(onAccept) {
                        onAccept(value);
                    }
                }}
            />
        );
    }
);


export default WiTextMask;
