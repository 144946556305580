import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useI18n } from "@wi-core/ui";

import LocaleMap from "./configs/LocaleMap";
import DateLocalizationProviderProps from "./@types/DateLocalizationProviderProps";


const DateLocalizationProvider = (props: DateLocalizationProviderProps) => {

    const {
        children
    } = props;

    const { lang } = useI18n();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={LocaleMap[lang.language]}>
            {children}
        </LocalizationProvider>
    )
}

export default DateLocalizationProvider;