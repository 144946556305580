import { WiLabelColor } from "@wi-themes/minimal-override";

import InconsistenciesStatus from "../@types/inconsistencies-status/InconsistenciesStatus";


const InconsistenciesStatusColor: {[key: string]: WiLabelColor} = {
    [InconsistenciesStatus.Block]: 'error',
    [InconsistenciesStatus.Justify]: 'warning',
    [InconsistenciesStatus.Ok]: 'info'
}

export default InconsistenciesStatusColor;