enum AppointmentStatus {
    Scheduling = "Scheduling", //Em agendamento
    Scheduled = "Scheduled", // Agendado
    Confirmed = "Confirmed", // Confirmado
    Canceled = "Canceled", // Cancelado
    AwaitingAttendance = "AwaitingAttendance", // Aguardando atendimento
    Accomplished = "Accomplished", // Realizado
    NoShow = "NoShow", // Não comparecimento
    Transfer = "Transfer", // À transferir
}

export default AppointmentStatus;